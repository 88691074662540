<template>
  <div class="bg">
    <div class="content">
      <div class="con">
        <div id="id-export-qrcode">
          <div class="h1">
            <strong>{{ qrCodeTitle }}</strong>
          </div>
          <div class="h2">Healthcare ePass</div>
          <div class="qr">
            <vue-qrcode
              :value="qrCodeContent"
              type="image/png"
              :color="qrCodeColor"
            />
          </div>
          <div class="name">
            <span>{{ qrCodeName }}</span>
          </div>
        </div>
        <a href="" class="bt" @click.prevent="saveAsImage">
          <span>Save</span>
        </a>
        <div class="f4">
          Your Healthcare ePass has been successfully obtained. Please save your
          ePass and visit the Health Station for your measurement.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import VueQrcode from 'vue-qrcode'
  import html2canvas from 'html2canvas'
  import { useStore } from 'vuex'

  const { t } = useI18n()
  const qrCodeTitle = ref('')
  const qrCodeName = ref('')
  const qrCodeContent = ref('')
  const qrCodeColor = ref({ dark: '#000000ff', light: '#ffffffff' })

  const store = useStore()
  const epass = computed(() => store.state.otp.epass)

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }

  const saveAsImage = async () => {
    // try {
    //   await navigator.share({
    //     // files: [file],
    //     files: undefined,
    //     title: 'Healthcare ePass',
    //     text: 'My Healthcare ePass',
    //     url: undefined,
    //   })
    // } catch (e) {
    //   console.error(e)
    // }

    const element = document.getElementById('id-export-qrcode')
    html2canvas(element).then((canvas) => {
      if (isMobile()) {
        // 為移動設備保存圖片
        canvas.toBlob((blob) => {
          const file = new File([blob], 'healthcare-epas-mobile.png', {
            type: 'image/png',
          })
          if (navigator.share) {
            navigator
              .share({
                files: [file],
                title: 'Healthcare ePass',
                text: 'My Healthcare ePass',
              })
              .catch(console.error)
          } else {
            // 如果 Web Share API 不可用，回退到創建下載鏈接
            const link = document.createElement('a')
            link.download = 'healthcare-epass-mobile.png'
            link.href = URL.createObjectURL(blob)
            link.click()
          }
        }, 'image/png')
      } else {
        // 為桌面設備下載圖片
        const link = document.createElement('a')
        link.download = 'healthcare-epass.png'
        link.href = canvas.toDataURL('image/png')
        link.click()
      }
    })
  }

  onMounted(() => {
    if (!epass.value) {
      alert('ePass not found')
    }
    // 將 base64 字串解碼為普通字串
    const decodedString = Buffer.from(epass.value, 'base64').toString('utf-8')

    // 根據 '::' 拆解字串
    const splitStrings = decodedString.split('::')
    qrCodeTitle.value = splitStrings[0]
    qrCodeName.value = splitStrings[2]
    qrCodeContent.value = epass.value
  })

  // const saveAsImage = () => {
  //   const element = document.getElementById('id-export-qrcode')
  //   if (element) {
  //     html2canvas(element).then((canvas) => {
  //       const link = document.createElement('a')
  //       link.href = canvas.toDataURL('image/png')
  //       link.download = 'qrcode.png'
  //       link.click()
  //     })
  //   }
  // }
</script>

<style lang="less" scoped>
  @charset "utf-8";
  body {
    font-family: Arial, sans-serif;
    padding: 0;
    margin: 0;
  }
  a,
  img {
    outline: none;
  }
  input,
  textarea {
    outline: none;
  }
  .bg {
    position: relative;
    background: linear-gradient(to bottom, #f3f3f3, #ffffff);
  }
  .content {
    position: relative;
    width: 490px;
    margin: 0 auto;
    background: linear-gradient(to bottom, #f3f3f3, #ffffff);
  }
  .content::before,
  .content::after {
    position: relative;
    width: 100%;
    content: '';
    display: block;
  }
  .content::before {
    padding-bottom: 6.66%;
  }
  .content::after {
    padding-bottom: 9.33%;
  }
  .con {
    position: relative;
    width: 85.3%;
    margin: 0 auto;
    text-align: center;
    color: #595959;
  }
  .h1,
  .h2,
  .h3 {
    position: relative;
    display: block;
  }
  .h1 {
    font-size: 40px;
    font-weight: bolder;
    margin: 0 auto 14px;
  }
  .h2 {
    font-size: 30px;
    font-weight: bold;
    margin: 0 auto 35px;
  }
  .h3 {
    font-size: 24px;
    color: #505050;
    font-weight: bold;
    margin: 0 auto 35px;
  }
  .f1 {
    font-size: 20px;
    line-height: 25px;
    min-height: 95px;
    text-align: left;
    padding: 0 3px;
    margin: 0 auto 33px;
  }
  .f1 span {
    display: inline-block;
  }
  .f2 {
    font-size: 14px;
    line-height: 18px;
    min-height: 93px;
    text-align: left;
    color: #ed1d24;
    padding: 0 3px;
    margin: 0 auto;
  }
  .f3 {
    font-size: 14px;
    line-height: 20px;
    min-height: 93px;
    text-align: left;
    padding: 0 3px;
    margin: 0 auto;
  }
  .f4 {
    font-size: 16px;
    line-height: 25px;
    color: #575555;
    text-align: left;
    margin: 30px auto 0;
  }
  .telnb {
    position: relative;
    width: 100%;
    padding: 0 10px;
    border-radius: 8px;
    margin: 0 auto 10px;
    font-family: Arial, sans-serif;
    font-size: 20px;
    line-height: 50px;
    color: #575555;
    border: 1px solid #14224a;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .otp {
    position: relative;
    width: 100%;
    text-align: center;
  }
  .otp input {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    margin: 0 2.1875% 10px 0;
    font-family: Arial, sans-serif;
    font-size: 32px;
    line-height: 50px;
    color: #575555;
    border: 1px solid #14224a;
    text-align: center;
    transition: all 0.5s;
    box-sizing: border-box;
  }
  .otp input:last-child {
    margin: 0 0 10px 0;
  }
  input:focus {
    border: 1px solid #ffa200;
  }
  ::-webkit-input-placeholder {
    color: #c4c4c4;
  }
  :-ms-input-placeholder {
    color: #c4c4c4;
  }
  ::placeholder {
    color: #c4c4c4;
  }
  .blu {
    color: #2f80ed;
  }
  .bt {
    position: relative;
    width: 100%;
    padding-bottom: 15.625%;
    border-radius: 10px;
    display: block;
    background-color: #8b2331;
    margin: 0 auto 10px;
    transition: all 0.5s;
  }
  .bt:hover {
    background-color: #b52639;
  }
  .bt span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }
  .qr {
    position: relative;
    width: 74.375%;
    margin: -7px auto 15px;
    display: block;
  }
  .qr img {
    position: relative;
    width: 100%;
    display: block;
  }
  .name {
    position: relative;
    width: 100%;
    padding-bottom: 16.5625%;
    border-radius: 5px;
    margin: 15px auto 50px;
  }
  .name span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: #595959;
  }
  @media only screen and (max-width: 500px) {
    .content {
      width: 100%;
    }
  }
  @media only screen and (max-width: 450px) {
    .h1 {
      font-size: 8.88vw;
      margin: 0 auto 3.11vw;
    }
    .h2 {
      font-size: 6.6vw;
      margin: 0 auto 7.7vw;
    }
    .h3 {
      font-size: 5.33vw;
      margin: 0 auto 7.7vw;
    }
    .f1 {
      font-size: 4.4vw;
      line-height: 5.5vw;
      min-height: 21.1vw;
      padding: 0 0.6vw;
      margin: 0 auto 7.3vw;
    }
    .f2 {
      font-size: 3.11vw;
      line-height: 4vw;
      min-height: 20.6vw;
      padding: 0 0.6vw;
    }
    .f3 {
      font-size: 3.11vw;
      line-height: 4.4vw;
      min-height: 20.6vw;
      padding: 0 0.6vw;
    }
    .f4 {
      font-size: 3.55vw;
      line-height: 5.5vw;
      margin: 6.6vw auto 0;
    }
    .telnb {
      padding: 0 2.2vw;
      border-radius: 1.7vw;
      margin: 0 auto 2.2vw;
      font-size: 4.4vw;
      line-height: 11.11vw;
    }
    .otp {
      width: 120%;
      left: -10%;
    }
    .otp input {
      width: 11.11vw;
      height: 11.11vw;
      border-radius: 1.7vw;
      margin: 0 2.1875% 2.2vw 0;
      font-size: 7.11vw;
      line-height: 11.11vw;
    }
    .otp input:last-child {
      margin: 0 0 2.2vw 0;
    }
    .bt {
      border-radius: 2.2vw;
      margin: 0 auto 2.2vw;
    }
    .bt span {
      font-size: 5.3vw;
    }
  }
  @media only screen and (max-width: 375px) {
    .h1 {
      font-size: 10.6vw;
      margin: 0 auto 3.72vw;
    }
    .h2 {
      font-size: 8vw;
      margin: 0 auto 9.33vw;
    }
    .h3 {
      font-size: 6.4vw;
      margin: 0 auto 9.33vw;
    }
    .f1 {
      font-size: 5.33vw;
      line-height: 6.6vw;
      min-height: 25.33vw;
      padding: 0 0.8vw;
      margin: 0 auto 8.8vw;
    }
    .f2 {
      font-size: 3.72vw;
      line-height: 4.8vw;
      min-height: 24.8vw;
      padding: 0 0.6vw;
    }
    .f3 {
      font-size: 3.72vw;
      line-height: 5.33vw;
      min-height: 24.8vw;
      padding: 0 0.8vw;
    }
    .f4 {
      font-size: 4.26vw;
      line-height: 6.6vw;
      margin: 8vw auto 0;
    }
    .telnb {
      padding: 0 2.6vw;
      border-radius: 2.13vw;
      margin: 0 auto 2.6vw;
      font-size: 5.33vw;
      line-height: 13.33vw;
    }
    .otp {
      width: 100%;
      left: 0;
    }
    .otp input {
      width: 13.33vw;
      height: 13.33vw;
      border-radius: 2.66vw;
      margin: 0 2.4vw 2.6vw 0;
      font-size: 8.53vw;
      line-height: 13.33vw;
    }
    .otp input:last-child {
      margin: 0 0 2.6vw 0;
    }
    .bt span {
      font-size: 6.4vw;
    }
  }
</style>
